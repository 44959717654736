import { Controller } from "@hotwired/stimulus"
import Swiper from 'swiper/bundle';

export default class extends Controller {
  static values = { layout: String, freeMode: Boolean }

  connect() {
    this.swiper = new Swiper(this.element, {
      direction: 'horizontal',
      loop: false,
      freeMode: this.freeModeValue,
      grabCursor: true,
      breakpoints: this.breakpointConfig(this.layoutValue),
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      }
    });
  }

  disconnect() {
    if (this.swiper) {
      console.log('destroying swiper');
      this.swiper.destroy();
    }
  }

  breakpointConfig(layout) {
    switch (layout) {
      case 'loggable-card':
        return {
          0: { slidesPerView: 1.5, slidesPerGroup: 1, spaceBetween: 10 },
          768: { slidesPerView: 2.5, slidesPerGroup: 2, spaceBetween: 15 },
          1024: { slidesPerView: 3.5, slidesPerGroup: 2, spaceBetween: 15 }
        }
      case 'stats':
        return {
          0: { slidesPerView: 2.5, slidesPerGroup: 1, spaceBetween: 10 },
          768: { slidesPerView: 3.5, slidesPerGroup: 2, spaceBetween: 15 },
          1024: { slidesPerView: 5.5, slidesPerGroup: 2, spaceBetween: 15 },
          1440: { slidesPerView: 6, slidesPerGroup: 2, spaceBetween: 15 }
        }
      case 'media-attachments':
        return {
          0: { slidesPerView: 1.5, slidesPerGroup: 1, spaceBetween: 10 },
          768: { slidesPerView: 2.5, slidesPerGroup: 2, spaceBetween: 15 },
          1024: { slidesPerView: 3.5, slidesPerGroup: 3, spaceBetween: 15 }
        }
      case 'explore-badges':
        return {
          0: { slidesPerView: 2.1, slidesPerGroup: 1, spaceBetween: 10 },
          768: { slidesPerView: 3.5, slidesPerGroup: 2, spaceBetween: 15 },
          1024: { slidesPerView: 4.5, slidesPerGroup: 3, spaceBetween: 15 }
        }
      default:
        return {
          0: { slidesPerView: 1.5, slidesPerGroup: 1, spaceBetween: 10 },
          768: { slidesPerView: 3.5, slidesPerGroup: 2, spaceBetween: 15 },
          1024: { slidesPerView: 4.5, slidesPerGroup: 3, spaceBetween: 15 }
        }
    }
  }
}
