import { Controller } from "@hotwired/stimulus"
import L from "leaflet"
import { GestureHandling } from "leaflet-gesture-handling"
import 'leaflet.markercluster'
import * as Handlebars from 'handlebars';

const popupTemplate = `
  <div class="d-flex flex-column gap-1">
    {{#if activity.date}}
    <div style="font-size: 0.8em">
      <i class="bi bi-calendar3 me-1 text-primary"></i> <small class="text-muted">{{activity.date}}</small>
    </div>
    {{/if}}
    <span class="d-block fw-bolder">{{activity.title}}</span>
    <small class="d-block text-muted">{{activity.subtext}}</small>
    {{#if activity.subtext2}}
    <small class="d-block text-secondary"><em>{{activity.subtext2}}</em></small>
    {{/if}}
    {{#if activity.url}}
    <a href="{{activity.url}}" data-turbo="false" target="_blank" class="mt-2 d-block text-decoration-none">View Details <i class="bi bi-box-arrow-up-right"></i></a>
    {{/if}}
  </div>
`

export default class extends Controller {
  static targets = ["container"]
  static values = { activities: String, handleGestures: Boolean }

  connect() {
    this.showMap();
  };

  disconnect() {
    if (this.map) {
      this.map.remove();
    }
  }

  showMap() {
    L.Map.addInitHook("addHandler", "gestureHandling", GestureHandling);
    var map = L.map(this.containerTarget, {gestureHandling: this.handleGesturesValue});
    console.log(this.handleGesturesValue);

    L.control.scale({'imperial': true, 'metric': false}).addTo(map);

    var outdoors = L.tileLayer('https://api.mapbox.com/styles/v1/allroadstaken/clzbxv6l3002l01p66v9eb66s/tiles/256/{z}/{x}/{y}@2x?access_token=pk.eyJ1IjoiYWxscm9hZHN0YWtlbiIsImEiOiJjbDk3azZyZnYwY29mM3BsOW1mdjE2ZDRkIn0.Dlg5NsF2Y08HVZ6vVn4qQQ', {
      maxZoom: 19,
      minZoom: 1,
      attribution: '&copy; <a href="https://www.mapbox.com/about/maps/">Mapbox</a> &copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
    })

    var satelite = L.tileLayer('https://api.mapbox.com/styles/v1/allroadstaken/clzbzmnad00r601pa2srzfnrj/tiles/256/{z}/{x}/{y}@2x?access_token=pk.eyJ1IjoiYWxscm9hZHN0YWtlbiIsImEiOiJjbDk3azZyZnYwY29mM3BsOW1mdjE2ZDRkIn0.Dlg5NsF2Y08HVZ6vVn4qQQ', {
      maxZoom: 19,
      minZoom: 1,
      attribution: '&copy; <a href="https://www.mapbox.com/about/maps/">Mapbox</a> &copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
    })

    map.addLayer(outdoors);

    var baseMaps = {
      "Outdoors": outdoors,
      "Satelite": satelite
    };

    var overlayMaps = {}

    L.control.layers(baseMaps, overlayMaps).addTo(map)

    let jsonActivities = JSON.parse(this.activitiesValue);
    let bounds = [];

    var markers = L.markerClusterGroup();
    jsonActivities.forEach(function (activity) {
      markers.addLayer(L.marker([activity.lat, activity.lng])
        .bindPopup(Handlebars.compile(popupTemplate)({activity: activity})));
      bounds.push([activity.lat, activity.lng]);
    });
    map.addLayer(markers);
    map.fitBounds(bounds);
  };

  getLocation() {
    let controller = this;

    var options = {
      enableHighAccuracy: true,
      timeout: 5000,
      maximumAge: 60000
    };
    function success(geo) {
      controller.showMap(geo.coords.latitude, geo.coords.longitude);
    };
    function failure(err) {
      console.warn(`ERROR(${err.code}): ${err.message}`);
      if (err.code == 1) {
        alert("Permission to determine your current location was denied. Please allow location access and try again.");
      } else if (err.code == 2) {
        alert("Your location could not be determined. Please try again or use an alternate method.");
      } else if (err.code == 3) {
        alert("Your location could not be determined. Please try again.");
      }
    };

    navigator.geolocation.getCurrentPosition(success, failure, options);
  };
}

// <div data-controller="map" data-map-target="container" data-map-zoom-value="5" data-map-center-lat="39.8283" data-map-center-lng="-98.5795" data-map-id="trails-map" class="map mb-3" style="height: 500px;"></div>

